import "./css/index.scss";

const ContactCard = () => {
  return (
    <div className="contact-card-container">
      <a href="tel:+66938892644" className="tel-section">
        <div className="tel-section-left">
          <img
            src="/assets/images/logo/phone-logo.png"
            alt="phone-icon"
            className="phone-icon"
          />
        </div>
        <div className="tel-section-right">
          <p className="tel-label">โทร</p>
          <p className="tel-number">093-889-2644</p>
        </div>
      </a>

      <div className="social-section">
        <div className="social-container">
          <a
            href="https://www.facebook.com/profile.php?id=61558506964145&mibextid=ZbWKwL"
            target="_blank"
          >
            <img
              src="/assets/images/logo/facebook-logo.png"
              alt="facebook-icon"
              className="social-icon"
            />
          </a>
        </div>
        <div className="social-container">
          <a
            href="https://www.facebook.com/profile.php?id=61558506964145&mibextid=ZbWKwL"
            target="_blank"
          >
            <img
              src="/assets/images/logo/line-logo.png"
              alt="line-icon"
              className="social-icon"
            />
          </a>
        </div>
        <div className="social-container">
          <a
            href="https://www.facebook.com/profile.php?id=61558506964145&mibextid=ZbWKwL"
            target="_blank"
          >
            <img
              src="/assets/images/logo/whatapp-logo.png"
              alt="whatapp-icon"
              className="social-icon"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
