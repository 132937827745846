/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import parse from "html-react-parser";
import "./css/index.scss";

/**
 * A component that displays user information.
 * @param {{ imageThumbnail?: string, description:string }} props
 */
const NewsDetailCard = (props) => {
  const { imageThumbnail, description } = props;
  return (
    <div className="news-detail-card">
      <div className="news-content-container">
        <div className="news-detail-image-container">
          {imageThumbnail && (
            <img src={imageThumbnail} alt="News Image Thumbnail" />
          )}
        </div>
        <div>
          <div className="news-detail-description">{parse(description)}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetailCard;
