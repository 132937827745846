export const HTTP_BASKET_ORDER_FETCHING = "HTTP_BASKET_ORDER_FETCHING";
export const HTTP_BASKET_ORDER_SUCCESS = "HTTP_BASKET_ORDER_SUCCESS";
export const HTTP_BASKET_ORDER_FAILED = "HTTP_BASKET_ORDER_FAILED";

///////////////////////// Localization Begin /////////////////////////
export const NOT_CONNECT_NETWORK = "NOT_CONNECT_NETWORK";
export const NETWORK_CONNECTION_MESSAGE = "NETWORK_CONNECTION_MESSAGE";

// export const serverUrl = "https://production-sky-pulse-news-server-39z1.onrender.com";
export const serverUrl =
  "https://production-sky-pulse-news-server.onrender.com";
// export const serverUrl = "http://localhost:3030";

export const apiServerUrl = serverUrl; // + "" "/api/v1"

export const imgDefaultUrl = `./assets/images/default/df-img.png`;
export const videoDefaultUrl = `./assets/images/default/df-vdo.png`;
export const imgDefaltCardCourse = `./assets/images/default/df-card-course-img.png`;
export const img404notfound = `./assets/images/notfound/404notfound.jpg`;

export const paginate = 10000; // limit 10 k
export const sortASC = "ASC";
export const sortDESC = "DESC";

export const YES = "YES";
export const NO = "NO";
export const OK = "ok";
export const NOK = "nok";

export const server = {
  /**
   * BACKOFFICE
   */
  // ------------------------ ADS BANNERS -----------------------------------------------------------
  GET_ADS_BANNERS_URL: `/api/adsBanners`,
  GET_ADS_BANNERS_BY_ID_URL: `/api/adsBanners`,

  INSERT_ADS_BANNERS_URL: `/api/adsBanners`,

  UPDATE_ADS_BANNERS_URL: `/api/adsBanners`,

  DELETE_ADS_BANNERS_BY_ID_URL: `/api/adsBanners`,

  // ------------------------ NEWS -----------------------------------------------------------
  GET_NEWS_URL: `/api/news`,
  GET_NEWS_BY_ID_URL: `/api/news`,

  INSERT_NEWS_URL: `/api/news`,
  INSERT_NEWS_CONTENT_URL: `/api/news-contents`,

  UPDATE_NEWS_URL: `/api/news`,
  UPDATE_NEWS_CONTENT_URL: `/api/news-contents`,

  DELETE_NEWS_BY_ID_URL: `/api/news`,
  DELETE_NEWS_CONTENT_BY_ID_URL: `/api/news-contents`,

  // ------------------------ UPLOADS --------------------------------------------------------
  UPLOAD_FILE: `/api/upload`,
  UPLOAD_FILES: `/api/upload/multiple`,

  // ------------------------ ERP ------------------------------------------------------------
  GET_ERP_SIGNIN_ID_URL: `/api/auth/signIn`,
  GET_ERP_SIGNUP_ID_URL: `/api/auth/signup`,
  GET_ERP_SIGNOUT_ID_URL: `/api/auth/signout`,
  GET_ERP_LIST_URL: ``,

  /**
   * FRONTEND
   */
  // ------------------------ HOME PAGE -----------------------------------------------------------
  GET_HOMEPAGE_TOP_VIEW_NEWS_TODAY_URL: `/api/homePage/topViewNewsToday`,
  GET_HOMEPAGE_ADS_BANNERS_URL: `/api/homePage/adsBanners`,
  GET_HOMEPAGE_NEWS_URL: `/api/homePage/news`,

  // ------------------------ NEWS DETAIL PAGE -----------------------------------------------------------
  GET_NEWSDETAILPAGE_URL: `/api/newsDetailPage`,
  GET_NEWSDETAILPAGE_ADD_COUNT_VIEW_NEWS_URL: `/api/newsDetailPage/addCountViewNews`,
};
