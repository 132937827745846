/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */

import { useState, useEffect } from "react";
import "./css/index.scss";
import "./css/paginate.scss";
import { Input, Row, Col, Button, Pagination } from "antd";
import axios from "axios";
import "moment/locale/th";
import LoadingBgBlack from "../loading/bgBlack";
import { timeout } from "../../../utils/Timeout";

import { useNavigate } from "react-router-dom";
import { serverUrl } from "../../../constants";

function NewsList() {
  const navigate = useNavigate();
  const { Search } = Input;

  const [news, setNews] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [newsPerPage] = useState(10);
  const [searchTitle, setSearchTitle] = useState("");
  const [loading, setLoading] = useState(true);
  const [totalNews, setTotalNews] = useState(0);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const fetchPageData = async () => {
    setLoading(true);
    // await timeout(1000);
    try {
      const response = await axios.get(serverUrl + "/api/homePage/news", {
        params: {
          page: currentPage,
          size: newsPerPage,
          title: searchTitle,
        },
      });
      setNews(response.data.formData.results);
      setTotalNews(response.data.formData.totalItems);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  // Debouncing
  useEffect(() => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchPageData();
    }, 500);

    setDebounceTimeout(timeout);
  }, [searchTitle, currentPage]);

  useEffect(() => {
    fetchPageData(currentPage);
  }, [currentPage]);

  const onChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearchTitle(e.target.value);
  };

  return (
    <div className="news-list-container">
      <div className="container">
        {/* <div style={{ paddingBottom: 8 }}> */}
        <div className="search">
          <div className="title-container">
            <p className="title">ข่าวล่าสุด</p>
          </div>
          <div className="search-input">
            <Search
              enterButton
              placeholder="ค้นหาข่าว"
              onChange={onSearch}
              value={searchTitle}
              className="search-bar"
            />
          </div>
        </div>
        {/* </div> */}

        <div className="news-container">
          {!loading ? (
            <Row gutter={[24, 24]}>
              {news.map((item, index) => (
                <Col
                  span={24}
                  md={index === 0 ? 24 : 12}
                  xl={index === 0 ? 16 : 8}
                  key={index}
                >
                  <div
                    className="big-news-card"
                    onClick={() => {
                      navigate("/news/" + item.id);
                    }}
                  >
                    <div>
                      <img
                        src={item.imageThumbnail}
                        alt={item.title}
                        className="image-container"
                      />
                    </div>
                    <div className="news-title-container">
                      <p className="news-title">{item.title}</p>
                      <Button
                        type="primary"
                        className="news-readmore-btn"
                        onClick={() => {
                          navigate("/news/" + item.id);
                        }}
                      >
                        อ่านต่อ
                      </Button>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <LoadingBgBlack loading={loading} />
          )}
          {news.length > 0 ? (
            <Row className="paginate-container">
              <Pagination
                size="small"
                current={currentPage}
                total={totalNews}
                defaultPageSize={newsPerPage}
                onChange={onChange}
              />
            </Row>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default NewsList;
