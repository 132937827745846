import "../intro/css/index.scss";

const Intro = () => {
  return (
    <div className="intro-container">
      <img
        src={"/assets/images/home/intro.png"}
        alt="sky-pulse-intro"
        className="intro-image"
      />
    </div>
  );
};

export default Intro;
