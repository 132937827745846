/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import "../header/css/index.css";
import "../header/css/index.scss";
// import styled from 'styled-components'
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Drawer, Button, Menu } from "antd";
import { Icon } from "@iconify/react";
import { useDimensions } from "../../mamager/ScreenManager";
import { scroller } from "react-scroll";

export default function Header(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useDimensions();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const [state, setState] = useState({
    current: "mail",
    visible: false,
  });

  const showDrawer = () => {
    setState({
      visible: true,
    });
  };

  const onClose = () => {
    setState({
      visible: false,
    });
  };

  const items = [
    {
      key: "1",

      onClick: () => {
        openPath("news");
      },
      label: "ข่าว",
    },
    {
      key: "2",

      onClick: () => {
        navigate("/about-us");
      },
      label: "เกี่ยวกับเรา",
    },
  ];

  const genRightMenu = () => {
    return <Menu mode="inline" items={items} />;
  };

  const openPath = (menu) => {
    let path = location.pathname;
    if (menu === "news") {
      if (path === "/" || path === "/home") {
        scroller.scrollTo("news", {
          duration: 1500,
          delay: 100,
          smooth: true,
          offset: 0,
        });
      } else {
        navigate("/?menu=news");
      }
    }
  };

  return (
    <div className="header-container">
      <div className="menu-container">
        <div className="left-side">
          <div className="menu-logo-container">
            <img
              src={"/assets/images/logo/skypulse_logo_and_text.jpg"}
              alt="sky-pulse-logo"
              className="menu-logo-image"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
          {width > 992 ? (
            <div className="menu-list">
              <div
                onClick={() => {
                  openPath("news");
                }}
                className="divStyle"
                style={{ marginLeft: 20, minWidth: 96 }}
              >
                <div style={{ cursor: "pointer" }}>
                  <label
                    style={{ padding: 12, fontSize: 20, cursor: "pointer" }}
                  >
                    ข่าว
                  </label>
                </div>
              </div>
              <div
                onClick={() => {
                  navigate("/about-us");
                }}
                className="divStyle"
                style={{ minWidth: 96 }}
              >
                <div style={{ cursor: "pointer" }}>
                  <label
                    style={{ padding: 12, fontSize: 20, cursor: "pointer" }}
                  >
                    เกี่ยวกับเรา
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {width > 992 ? (
          <div
            style={{
              display: "flex",
              paddingTop: 2,
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          ></div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Drawer
              title={<p className="drawer-title">SKY PULSE NEWS</p>}
              placement="right"
              closable={false}
              onClose={onClose}
              open={state.visible}
              style={{ padding: "0px" }}
              size="default"
            >
              {genRightMenu()}
            </Drawer>

            <Button
              type="text"
              onClick={showDrawer}
              style={{
                height: 70,
                width: 75,
                borderRadius: 0,
              }}
            >
              <Icon icon="charm:menu-hamburger" className="hamburger-icon" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
