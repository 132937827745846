import { server } from "../../../../../constants";
import { httpClient } from "../../../../../utils/HttpClient";

// Ads Banners
const insertAdsBannersFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.post(server.INSERT_ADS_BANNERS_URL, body, {
      headers: {
        "x-access-token": accessToken,
      },
    });
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const getAdsBannersFetch = async (param, body, accessToken) => {
  // Done
  try {
    const result = await httpClient.get(
      server.GET_ADS_BANNERS_URL +
        `?name=${param.name}&isActive=${param.isActive}&page=${param.page}&size=${param.size}`,
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const getAdsBannersByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.get(
      server.GET_ADS_BANNERS_BY_ID_URL + `/${param.id}`,
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    );
    if (result.data.isSuccess) {
      return result.data.formData;
    } else {
      return null;
    }
  } catch (err) {
    // status 404
    return null;
  }
};

const updateAdsBannersFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.put(
      server.UPDATE_ADS_BANNERS_URL + `/${param.id}`,
      body,
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

const deleteAdsBannersByIdFetch = async (param, body, accessToken) => {
  try {
    const result = await httpClient.delete(
      server.DELETE_ADS_BANNERS_BY_ID_URL + `/${param.id}`,
      {
        headers: {
          "x-access-token": accessToken,
        },
      }
    );
    return result?.data ?? null;
  } catch (err) {
    // status 404
    return null;
  }
};

export {
  // get
  getAdsBannersFetch,
  getAdsBannersByIdFetch,

  // insert
  insertAdsBannersFetch,

  // update
  updateAdsBannersFetch,

  // delete
  deleteAdsBannersByIdFetch,
};
