import "./css/index.scss";

import Header from "../../common/components/header";
import ContactCard from "./ContactCard";

import "moment/locale/th";

const About = () => {
  return (
    <>
      <Header />

      <div className="about-container">
        <div className="container">
          <div>
            <h1 className="about-page-title">ติดต่อเรา</h1>
          </div>
          <div className="about-container">
            <div className="about-container-left">
              <img
                src="/assets/images/about_us/office.jpg"
                alt="office"
                className="office-image"
              />
              <div className="contact-card-lg">
                <ContactCard />
              </div>
            </div>
            <div className="about-container-right">
              <div className="detail">
                <p>
                  สำนักข่าวของเรา ตั้งอยู่ในจังหวัดขอนแก่น
                  มีความมุ่งมั่นที่จะนำเสนอข่าวสาร ที่ทันเหตุการณ์
                  และเชื่อถือได้ เพื่อบริการชุมชนท้องถิ่นของเรา
                  ด้วยทีมงานมืออาชีพที่ทำงานด้วยความตั้งใจและรวดเร็ว
                </p>
                <p>
                  เรามุ่งมั่นที่จะเป็นแหล่งข้อมูลที่ชาวขอนแก่นสามารถพึ่งพาได้
                  ไม่ว่าจะเป็นข่าวสารการเมือง เศรษฐกิจ สังคม
                  หรือเหตุการณ์สำคัญอื่น ๆ ที่เกิดขึ้นในท้องถิ่น
                  เราพร้อมนำเสนอข่าวสารที่ตรงไปตรงมาและเป็นกลาง
                  เพื่อให้ประชาชนได้รับข้อมูลที่ถูกต้องและครบถ้วนในทุกด้าน
                </p>
                <p>
                  สำนักข่าวของเราไม่เพียงแต่ให้ความสำคัญกับการรายงานข่าวอย่างรวดเร็ว
                  แต่ยังเน้นความถูกต้องและความชัดเจนในทุกข่าวที่เรานำเสนอ
                  เราเชื่อว่าการสื่อสารที่ดีจะช่วยส่งเสริมความเข้าใจและความร่วมมือในชุมชน
                  ซึ่งเป็นเป้าหมายสำคัญของเราที่จะทำให้ขอนแก่นเป็นเมืองที่เข้มแข็งและเจริญรุ่งเรือง
                </p>
                <p>
                  ขอขอบคุณที่ไว้วางใจในสำนักข่าวของเรา
                  เราสัญญาว่าจะทำงานด้วยความมุ่งมั่นและพัฒนาอย่างต่อเนื่อง
                  เพื่อบริการข่าวสารที่ดีที่สุดสำหรับชุมชนของเรา
                </p>
              </div>
            </div>
            <div className="contact-card-sm">
              <ContactCard />
            </div>
          </div>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default About;
