/* eslint-disable jsx-a11y/alt-text */
import { useState, useRef } from "react";
import { Col, Button, Form, Upload, Flex } from "antd";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Notification } from "../../../../common/components/notification";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { apiServerUrl, server } from "../../../../constants";
import { insertNewsContentFetch, updateNewsContentFetch } from "./API";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";

const ContentForm = (props) => {
  const {
    newsList,
    setNewsList,
    handleUpdateContent,
    contentSelected,
    modalNews,
    setContentSelected,
    newsId,
    contentIsEdited,
  } = props;
  const accessToken = sessionStorage.getItem("accessToken");
  const [detail, setDetail] = useState(
    contentSelected ? contentSelected.detail : null
  );
  const detailRef = useRef();
  const [imageThumbnailURL, setImageThumbnailURL] = useState({
    loading: false,
    imageUrl: contentSelected ? contentSelected.imageThumbnail : null,
  });

  const optionNewsImage = {
    name: "file",
    action: `${apiServerUrl}${server.UPLOAD_FILE}?type=news`,
    data: {
      name: "admin",
    },
    headers: {
      "x-access-token": accessToken,
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        let result = info.file.response;
        if (result?.isSuccess) {
          setImageThumbnailURL({
            imageUrl: result.formData.fileUrl,
            loading: false,
          });
        }
      } else {
        setImageThumbnailURL({
          imageUrl: imageThumbnailURL.imageUrl,
          loading: true,
        });
      }

      if (info.file.status === "done") {
        Notification("success", "เเจ้งเตือน!", "อัพโหลดรูปภาพสำเร็จ");
      } else if (info.file.status === "error") {
        Notification(
          "error",
          "เเจ้งเตือน!",
          "อัพโหลดรูปภาพไม่สำเร็จ กรุณาลองใหม่อีกครั้ง"
        );
      }
    },
    progress: {
      strokeColor: {
        "0%": "#FF7F00",
        "100%": "#FF7F00",
      },
      strokeWidth: 3,
      width: "10%",
      format: (percent) => `${parseFloat(percent.toFixed(0))}%`,
    },
  };

  const handleOnSubmit = async () => {
    try {
      if (modalNews.isEdited) {
        const body = {
          imageThumbnail: imageThumbnailURL.imageUrl,
          detail: detailRef.current,
          isActive: 1,
          newsId: newsId,
          id: contentSelected.id,
        };
        const result = await updateNewsContentFetch(
          contentSelected,
          body,
          accessToken
        );

        if (result.isSuccess) {
          Notification("success", "แก้ไขสำเร็จ");
          const updatedNews = newsList.map((news, index) => {
            if (index === contentSelected.index) {
              return body;
            }
            return news;
          });

          setNewsList(updatedNews);
          handleClearState();
          handleUpdateContent(false, false);
          contentIsEdited.current = true;
        } else {
          Notification("error", "ไม่สามารถแก้ไขได้ กรุณาลองใหม่อีกครั้ง");
        }
      } else {
        const body = {
          imageThumbnail: imageThumbnailURL.imageUrl,
          detail: detailRef.current,
          isActive: 1,
          newsId: newsId,
        };
        const result = await insertNewsContentFetch(null, body, accessToken);
        if (result.isSuccess) {
          const content = {
            imageThumbnail: imageThumbnailURL.imageUrl,
            detail: detailRef.current,
            isActive: 1,
            newsId: newsId,
            id: result.formData.id,
          };
          Notification("success", "บันทึกสำเร็จ");
          const news = newsList;
          news.push(content);
          setNewsList(news);
          handleClearState();
          handleUpdateContent(false, false);
          contentIsEdited.current = true;
        } else {
          Notification("error", "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง");
        }
      }
    } catch (error) {
      console.log("🚀 ~ handleOnSubmit ~ error:", error);
      Notification("error", "ไม่สามารถบันทึกได้ กรุณาลองใหม่อีกครั้ง");
    }
  };

  const handleClearState = () => {
    setDetail(null);
    detailRef.current = null;
    setImageThumbnailURL({
      loading: false,
      imageUrl: null,
    });
    setContentSelected(null);
  };

  return (
    <>
      <Col xs={24} md={12} xl={12}>
        <div style={{ display: "grid" }}>
          <label style={{ paddingBottom: 6 }}>ภาพเนื้อหาข่าว</label>
          {imageThumbnailURL?.imageUrl ? (
            <img
              style={{
                borderRadius: 8,
                maxWidth: "100%",
                border: "1px solid #EEEEEE",
              }}
              src={`${imageThumbnailURL.imageUrl}`}
            />
          ) : (
            <img
              style={{
                width: "100%",
                borderRadius: 8,
                border: "1px solid #C4C4C4",
              }}
              src={`./assets/images/default/df-img.png`}
            />
          )}
          <div style={{ paddingTop: 24, paddingBottom: 24 }}>
            <Upload
              {...optionNewsImage}
              accept="image/jpeg, image/png, image/jfif"
              style={{ width: "100%" }}
              maxCount={1}
              showUploadList={false}
            >
              <Button
                type="primary"
                style={{ width: "100%" }}
                disabled={imageThumbnailURL.loading}
                icon={
                  imageThumbnailURL.loading ? (
                    <LoadingOutlined />
                  ) : (
                    <UploadOutlined />
                  )
                }
              >
                อัพโหลดรูปภาพ
              </Button>
            </Upload>
          </div>
        </div>
      </Col>

      {/* Description */}
      <Col xs={24}>
        <Form.Item
          name="detail"
          label="รายละเอียดย่อย"
          rules={[{ required: true, message: "กรุณากรอกรายละเอียดย่อย" }]}
        >
          <CKEditor
            editor={ClassicEditor}
            config={{
              toolbar: {
                items: [
                  "heading",
                  "|",
                  "fontfamily",
                  "fontsize",
                  "|",
                  "bold",
                  "italic",
                  "underline",
                  "|",
                  "alignment",
                  "|",
                  "fontColor",
                  "fontBackgroundColor",
                  "|",
                  "bulletedList",
                  "numberedList",
                  "todoList",
                  "|",
                  "code",
                  "codeBlock",
                  "|",
                  "undo",
                  "redo",
                  "|",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                  "mergeTableCells",
                ],
                removeButtons: "Subscript,Superscript",
              },
              table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
              },
              height: 350,
            }}
            data={detail}
            onBlur={(event, editor) => {
              const data = editor.getData();
              detailRef.current = data;
            }}
          />
        </Form.Item>
      </Col>

      {/* Button Submit */}
      <Col xs={24}>
        <Flex gap={4} justify="flex-end">
          <Button
            style={{ width: 100, cursor: "pointer" }}
            onClick={() => {
              handleUpdateContent(false, false);
              handleClearState();
            }}
          >
            ยกเลิก
          </Button>
          <Button
            type="primary"
            style={{ width: 100, cursor: "pointer" }}
            onClick={handleOnSubmit}
          >
            {modalNews.isEdited ? "บันทึก" : "สร้าง"}
          </Button>
        </Flex>
      </Col>
    </>
  );
};

export default ContentForm;
