import { faker } from "@faker-js/faker";

export const dataNews = Array.from({ length: 6 }, (_, index) => ({
  news: {
    id: index + 1,
    imageThumbnail: faker.image.url(),
    title: faker.lorem.sentence(),
  },
  createdAt: faker.date.past(),
}));
