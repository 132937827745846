/* eslint-disable jsx-a11y/img-redundant-alt */
import parse from "html-react-parser";
import { Flex, Button, Popconfirm } from "antd";
import { Icon } from "@iconify/react";
import { Notification } from "../../../../common/components/notification";
import { deleteNewsByIdFetch } from "./API";

import "./css/index.scss";

const ContentCard = (props) => {
  const {
    setContentSelected,
    handleUpdateContent,
    content,
    index,
    handleNewsContentDelete,
  } = props;

  return (
    <div className="news-card">
      <div className="news-card-left">
        <img
          src={
            content.imageThumbnail
              ? content.imageThumbnail
              : "./assets/images/default/df-img.png"
          }
          alt="News Image"
        />
        <Flex justify="flex-start" align="center" gap={4}>
          <Button
            style={{
              width: 35,
              backgroundColor: "orange",
              border: "1px solid orange",
              color: "white",
              borderRadius: 50,
            }}
          >
            <div style={{ marginTop: 0, marginLeft: -8 }}>
              <Icon
                icon="typcn:edit"
                style={{
                  color: "white",
                  width: 20,
                  height: 20,
                }}
                onClick={() => {
                  setContentSelected({ ...content, index });
                  handleUpdateContent(true, true);
                }}
              />
            </div>
          </Button>
          <Popconfirm
            title="คุณยืนยันลบหรือไม่ ?"
            okText={<span style={{ width: 50 }}>ใช่</span>}
            onConfirm={async () => {
              await handleNewsContentDelete(content.id);
            }}
            cancelText={<span style={{ width: 50 }}>ไม่ใช่</span>}
          >
            <Button
              danger
              type="primary"
              style={{
                width: 35,
                borderRadius: 50,
              }}
            >
              <div style={{ marginTop: 0, marginLeft: -8 }}>
                <Icon
                  icon="fluent:delete-16-regular"
                  style={{
                    color: "white",
                    width: 20,
                    height: 20,
                  }}
                />
              </div>
            </Button>
          </Popconfirm>
        </Flex>
      </div>
      <div className="news-card-right">
        {content.detail && (
          <div className="news-card-html-parser">{parse(content.detail)}</div>
        )}
      </div>
    </div>
  );
};

export default ContentCard;
