/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { serverUrl } from "../../constants";
import Header from "../../common/components/header";
import NewsDetailCard from "./newsDetailCard";
import Footer from "../../common/components/footer";
import LoadingBgBlack from "../../common/components/loading/bgBlack";
import SEO from "../../common/components/seo";

import "moment/locale/th";
import "./css/index.scss";
import { Flex } from "antd";

const NewsDetail = () => {
  const { id } = useParams(); // Get the news ID from the URL
  const [news, setNews] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [ads, setAds] = useState(null);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const response = await axios.get(
          serverUrl + `/api/newsDetailPage/${id}`
        );
        setNews(response.data.formData[0]);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }

      try {
        const response = await fetch(serverUrl + "/api/homePage/adsBanners");
        const data = await response.json();
        const adses = data.formData;
        if (adses.length > 0) {
          let ads = adses[Math.floor(Math.random() * adses.length)];
          setAds(ads);
        } else {
        }
      } catch (error) {
        console.error("Error fetching the news:", error);
      }
    };

    fetchNewsDetail();
  }, [id]);

  const handleUpdateView = async () => {
    await axios.post(serverUrl + `/api/newsDetailPage/addCountViewNews/${id}`);
  };

  useEffect(() => {
    handleUpdateView();
  }, []);

  const url = encodeURIComponent(window.location.href); // ลิงก์ของหน้าปัจจุบัน
  // const title = encodeURIComponent(document.title); // ชื่อของหน้าเว็บ
  const text = encodeURIComponent(news?.title ?? '-'); // ข้อความที่ต้องการแชร์

  return (
    <>
      <Header />
      <div className="news-detail-page-container">
        {!loading ? (
          <>
            <SEO
              title={news?.title}
              description={news?.detail}
              name={"Sky Pulse News"}
              type={"article"}
              image={news?.imageThumbnail}
            />
            <div className="news-detail-container">
              <div className="news-detail-title-container">
                <h1 className="title">{news?.title}</h1>
                <div className="share-container">
                    <label style={{ paddingRight: 12 }}>แชร์</label>
                    <Flex gap="middle" align="center" justify="flex-start">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/assets/images/logo/facebook.png"
                          className="share-logo"
                        ></img>
                      </a>
                      <a
                        href={`https://twitter.com/intent/tweet?url=${url}&text=${text}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/assets/images/logo/twitter.png"
                          className="share-logo"
                        ></img>
                      </a>
                      <a
                        href={`https://social-plugins.line.me/lineit/share?url=${url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src="/assets/images/logo/line.png"
                          className="share-logo"
                        ></img>
                      </a>
                    </Flex>
                  </div>
              </div>
              <div className="news-detail-description-container">
                {news?.newsContents?.map((item, index) => {
                  return (
                    <NewsDetailCard
                      key={index}
                      imageThumbnail={item?.imageThumbnail}
                      description={item.detail}
                    />
                  );
                })}
                {ads ? (
                  <div className="random-ads">
                    <img src={ads.image} />
                  </div>
                ) : (
                  []
                )}
              </div>
            </div>
          </>
        ) : (
          <LoadingBgBlack loading={loading} />
        )}
      </div>
      <Footer />
    </>
  );
};

export default NewsDetail;
